import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { graphql } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import classNames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Bars1Src from '../img/svg/bars-1.svg';
import Bars2Src from '../img/svg/bars-2.svg';
import ImgBgExperts from '../img/svg/pageCompany/experts_bg.svg';
import ImgBgHero from '../img/svg/pageCompany/hero_bg.svg';
import ImgExpertsBgHero from '../img/svg/pageCompany/experts_slider_bg.svg';
import ImageStarsSrc from '../img/svg/stars.svg';

import Layout from '../components/Layout';
import ReferenceSwiper from '../components/ReferenceSwiper';
import CardStatic from '../components/CardStatic';
import VideoModalPreview from '../components/VideoModalPreview';
import Experience from '../components/sections/Experience';

import DataTestimonials from '../data/testimonials.json';
import DataExperts from '../data/experts.json';

export const CompanyPageTemplate = ({ 
    team, 
    hero, 
    gallery, 
    expertsIntro, 
    experts, 
    testimonial, 
    textA, 
    textB, 
    textC,
    experience 
  }) => {

  const [isMainSVGLoaded, setIsMainSVGLoaded] = useState(false);

  const classNameBackground = classNames('Company__hero__background', {
    'Company__hero__background--noImage': !isMainSVGLoaded
  });

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 17500,
    cssEase:'linear',
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 601,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 979,
        settings: {
          slidesToShow: 2
        }
      }
    ]
  };

  const dataExperts = () => {
    let items = DataExperts.items;
    let count = items.length;
    let middle = count / 2;
    if(count % 2 == 1) {
      middle = (count + 1) / 2
    }

    return {
      first: items.slice(0, middle), 
      second: items.slice(middle)
    }
  }

  const Expert = (item) => (
    <div className="Company__experts__line__item" key={item.id}>
      <div className="Company__expert">
        <img className="Company__expert__img" src={item.avatar} alt={'avatar ' + item.name} />
        <div className="Company__expert__cont">
          <h5 className="Company__expert__title">{item.name}</h5>
          <h6 className="Company__expert__subtitle">{item.title}</h6>
          <p className="Company__expert__text">
            {item.text}
          </p>
          <div className="Company__expert__tags">
            {item.tags && item.tags.map((tag, i) => (
            <div className="Company__expert__tag">#{tag.text}</div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  return (
  <div className="Company">
    <section className="Company__section Company__hero">
      <div className="Layout__background">
        <SVG src={ImageStarsSrc} className="Layout__stars" />
      </div>

      <div className={classNameBackground}>
        <div className="Company__hero__background__space"></div>
        <SVG
          src={ImgBgHero}
          className="Company__hero__background-image"
          onError={error => console.log(error.message)}
          onLoad={(src, hasCache) => setIsMainSVGLoaded(true)}
        />
      </div>
      <div className="Company__container">
        <div className="Company__hero-title-wrapper">
          <SVG
            src={Bars1Src}
            className="Company__hero-bar Company__hero-bar--1"
          />

          <SVG
            src={Bars2Src}
            className="Company__hero-bar Company__hero-bar--2"
          />

          <p className="Company__hero-title-label">{hero.label}</p>

          <h1 className="Company__hero-title">{hero.title}</h1>
        </div>

        <div className="Company__container-text Company__container-text--1">
          <ReactMarkdown children={hero.text} rehypePlugins={[rehypeRaw]} />
        </div>

        <ul className="Company__hero-items">
          {hero.items.map((item, i) => (
            <CardStatic
              key={i}
              tagName="li"
              className="Company__hero-item"
              title={item.name}
              iconSrc={item.icon.publicURL || item.icon}
              variants={['center']}
            >
            </CardStatic>
          ))}
        </ul>
      </div>
    </section>

    <section className="Company__expertsInfo Company__background">
      <div className="Company__container">
        <div className="Company__expertsInfo__container">
          <div className="Company__expertsInfo__container__col">
            <SVG
              src={ImgBgExperts}
              className="Company__expertsInfo__imgBg"
            />
            <VideoModalPreview 
              className="Company__expertsInfo__video"
              videoID={expertsIntro.videoID}
              channel={expertsIntro.channel}
              url_mp4={expertsIntro.url_mp4}
              url_webm={expertsIntro.url_webm}
            />
          </div>
          <div className="Company__expertsInfo__container__col">
            <h2 className="Company__expertsInfo__title">{expertsIntro.title}</h2>
            <div className="">
              <ReactMarkdown children={expertsIntro.text} rehypePlugins={[rehypeRaw]} />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="Company__experts Company__background">
      <div className="Company__experts__background">
        <SVG
          src={ImgExpertsBgHero}
          className="Company__experts__background-image"
        />
      </div>
      <div className="Company__container">
        <h3 className="Company__experts__title">
          <ReactMarkdown children={experts.title} rehypePlugins={[rehypeRaw]} />
        </h3>
        {/* <div className="Company__experts__text">
          <ReactMarkdown children={experts.text} rehypePlugins={[rehypeRaw]} />
        </div> */}
      </div>
      <div className="Company__experts__slider Company__experts__slider--a">
        <Slider {...settings}>
          {dataExperts().first.map((item, i) => (
            <Expert {...item} key={i} />
          ))}
        </Slider>
      </div>
      <div className="Company__experts__slider Company__experts__slider--b">
        <Slider {...settings}>
          {dataExperts().second.map((item, i) => (
            <Expert {...item} key={i} />
          ))}
        </Slider>
      </div>
    </section>

    <section className="Company__section Company__background">
        <div className="Company__container">        
          <h2 className="Company__subtitle">{gallery.title}</h2>

          <div className="Company__container-text Company__container-text--4">
            <ReactMarkdown children={textC} rehypePlugins={[rehypeRaw]} />
          </div>

          <ul className="Company__images">
            {gallery.items.map(({ alt, image, position }, i) => (
              <li key={i} className="Company__image-wrapper">
                <img
                  key={i}
                  className="Company__image"
                  alt={alt}
                  src={'/img/' + image.relativePath}
                  style={position ? { 'objectPosition': position } : { 'objectPosition': 'center' }}
                />
              </li>
            ))}
          </ul>

          {/* <div className="Company__container-text Company__container-text--2">
            <div style={{ textAlign: 'center' }}>
              <ReactMarkdown children={textD} />
            </div>
          </div> */}
        </div>
    </section>

    <section className="Company__testimonials Company__background">
      <div className="Company__testimonials__background">
        <SVG
          src={ImgExpertsBgHero}
          className="Company__testimonials__background-image"
        />
      </div>
      
      <div className="Company__container">
        <h2 className="Company__testimonials__title">{testimonial.title}</h2>
        <div className="Company__container-text Company__container-text--3">
          <ReactMarkdown children={textB} rehypePlugins={[rehypeRaw]} />
        </div>

        <div className="Company__testimonials__cont">
          <ReferenceSwiper data={DataTestimonials.workshops} />
        </div>
      </div>
    </section>

    <Experience 
      heading={experience.heading}
      items={experience.items}
    />
  </div>
  );
};

const CompanyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout variants={['backgrounded']} meta={frontmatter.meta}>
      <CompanyPageTemplate {...frontmatter} />
    </Layout>
  );
};

CompanyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CompanyPage;

export const companyPageQuery = graphql`
  query CompanyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "company-page" } }) {
      frontmatter {
        meta {
          title
          description
          image {
            publicURL
            name
          }
        }

        hero {
          title
          label
          text
          items {
            name
            icon {
              publicURL
              name
            }
          }
        }

        textB
        textC
        textD

        experts {
          title
        }

        expertsIntro {
          title
          text
          image {
            relativePath
            name
          }
          url_mp4 {
            publicURL
            name
          }
          url_webm {
            publicURL
            name
          }
          videoID
          channel
        }

        team {
          title
          people {
            name
            position
            image {
              relativePath
              name
            }
          }
        }

        gallery {
          title
          items {
            alt
            position
            image {
              relativePath
              name
            }
          }
        }

        testimonial {
          title
        }

        experience {
          heading
          items {
            text
            title
            icon {
              publicURL
              name
            }
            slug
          }
        }
      }
    }
  }
`;
